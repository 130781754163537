import CREATE_DIRECTORY_PERSON from '@/graphql/mutations/CreateDirectoryPerson';
import UPDATE_DIRECTORY_PERSON from '@/graphql/mutations/UpdateDirectoryPerson';
import GET_DIRECTORY_PERSONS_BY_OWNER_ID from '@/graphql/queries/GetDirectoryPersonsByOwnerId';
import GET_DIRECTORY_PERSON from '@/graphql/queries/GetDirectoryPerson';
import DELETE_DIRECTORY_PERSON from '@/graphql/mutations/DeleteDirectoryPerson';
import VALIDATE_DELETE_DIRECTORY_PERSON from '@/graphql/queries/ValidateDeleteDirectoryPerson';

import {
  directoryPersonToApiInput,
  getPersonRelationshipWithUser,
  enrichDirectoryPersonData,
} from '@/utilities';
import { RELATIONSHIP_TYPE } from '@/utilities/constants';

export default {
  async getDirectoryPersonData(_, id) {
    const {
      data: { getDirectoryPerson },
    } = await this.app.apolloProvider.defaultClient.query({
      fetchPolicy: 'no-cache',
      query: GET_DIRECTORY_PERSON,
      variables: {
        id,
      },
    });

    if (getDirectoryPerson)
      return await enrichDirectoryPersonData(getDirectoryPerson);
  },
  async getDirectoryPerson({ commit, dispatch, state }, id) {
    const enrichedUser = await dispatch('getDirectoryPersonData', id);

    if (!enrichedUser) return;
    if (enrichedUser.id === state.user.id) {
      commit('setUserDetails', enrichedUser);
      dispatch('progress/setProgress', null, { root: true });
      return;
    }
    const contacts = state.contacts.filter(
      (contact) => contact.id !== enrichedUser.id
    );
    commit('setContacts', [...contacts, enrichedUser]);
  },
  async updateUserDirectoryPerson({ commit, dispatch, state }, data) {
    const {
      data: { updateDirectoryPerson },
    } = await this.app.apolloProvider.defaultClient.mutate({
      mutation: UPDATE_DIRECTORY_PERSON,
      variables: {
        input: {
          id: state.user.id,
          firstName: data.firstName,
          middleName: data.middleName,
          lastName: data.lastName,
          dateOfBirth: data.dateOfBirth,
          residentialAddress: {
            country: data.country,
          },
        },
      },
    });
    if (!updateDirectoryPerson) return;

    const enrichedUser = await enrichDirectoryPersonData(updateDirectoryPerson);
    const previousUserCountry = state.user.residentialAddress?.country;
    commit('setUserDetails', enrichedUser);
    dispatch('onDirectoryPersonCountryUpdate', previousUserCountry);
    dispatch('progress/setProgress', null, { root: true });
  },
  async updateDirectoryPerson({ state, commit, dispatch }, data) {
    const personInput = directoryPersonToApiInput(data);
    if (data.relationshipWithUser) {
      personInput.relationships = [
        {
          type: data.relationshipWithUser,
          fromPersonId: state.user.id,
          toPersonId: data.id,
        },
      ];
    }
    if (data.isPartnerGuardian) {
      personInput.relationships.push({
        type: RELATIONSHIP_TYPE.CHILD,
        fromPersonId: data.partnerId,
        toPersonId: data.id,
      });
    }
    const {
      data: { updateDirectoryPerson },
    } = await this.app.apolloProvider.defaultClient.mutate({
      mutation: UPDATE_DIRECTORY_PERSON,
      variables: {
        input: personInput,
        notify: data.notifyContact,
      },
    });
    const enrichedUser = await enrichDirectoryPersonData(updateDirectoryPerson);
    if (enrichedUser?.id === state.user.id) {
      const previousUserCountry = state.user.residentialAddress?.country;
      commit('setUserDetails', enrichedUser);
      dispatch('onDirectoryPersonCountryUpdate', previousUserCountry);
      dispatch('progress/setProgress', null, { root: true });
      return;
    }
    if (
      data.relationshipWithUser &&
      data.relationshipWithUser !==
        getPersonRelationshipWithUser(state.user, enrichedUser.id)
    ) {
      const otherRelationship =
        state.user.relationships?.filter(
          (relationship) => relationship.toPersonId !== enrichedUser.id
        ) || [];

      commit('setUserDetails', {
        ...state.user,
        relationships: [
          ...otherRelationship,
          {
            type: data.relationshipWithUser,
            fromPersonId: state.user.id,
            toPersonId: enrichedUser.id,
          },
        ],
      });
    }
    const contacts = state.contacts.filter(
      (contact) => contact.id !== enrichedUser.id
    );
    commit('setContacts', [...contacts, enrichedUser]);
    dispatch('progress/setProgress', null, { root: true });
  },
  async getDirectoryPersonsByOwnerIdData(_, { ownerId, excludeSelf }) {
    const {
      data: { getDirectoryPersonsByOwnerId },
    } = await this.app.apolloProvider.defaultClient.query({
      fetchPolicy: 'no-cache',
      query: GET_DIRECTORY_PERSONS_BY_OWNER_ID,
      variables: {
        ownerId,
        excludeSelf: !!excludeSelf,
      },
    });

    if (getDirectoryPersonsByOwnerId) {
      return await Promise.all(
        getDirectoryPersonsByOwnerId.map(enrichDirectoryPersonData)
      );
    }
  },
  async getDirectoryPersonsByOwnerId(
    { commit, dispatch },
    { ownerId, personId }
  ) {
    const contacts = await dispatch('getDirectoryPersonsByOwnerIdData', {
      ownerId,
      excludeSelf: !personId,
    });

    if (!contacts) return;

    if (personId) {
      const userDetails = contacts.find((person) => person.id === personId);
      if (userDetails) {
        commit('setUserDetails', userDetails);
        dispatch('progress/setProgress', null, { root: true });
      }
    }

    commit(
      'setContacts',
      contacts.filter((person) => person.id !== personId)
    );
  },
  async createDirectoryPerson({ commit, dispatch, state }, data) {
    const contactInput = directoryPersonToApiInput(data, state.user.id);
    if (data.relationshipWithUser) {
      contactInput.relationships = [
        {
          type: data.relationshipWithUser,
          fromPersonId: state.user.id,
        },
      ];
    }
    if (data.isPartnerGuardian) {
      contactInput.relationships.push({
        type: RELATIONSHIP_TYPE.CHILD,
        fromPersonId: data.partnerId,
      });
    }
    const {
      data: { createDirectoryPerson },
    } = await this.app.apolloProvider.defaultClient.mutate({
      mutation: CREATE_DIRECTORY_PERSON,
      variables: {
        input: contactInput,
        notify: data.notifyContact,
      },
    });
    if (!createDirectoryPerson) return;

    const revealedContact = await enrichDirectoryPersonData(
      createDirectoryPerson
    );
    if (createDirectoryPerson.relationships?.length) {
      const relationshipWithUserObject =
        createDirectoryPerson.relationships.find(
          (relationship) => relationship.fromPersonId === state.user.id
        );
      if (relationshipWithUserObject) {
        commit('upSearchUserRelationships', relationshipWithUserObject);
      }
    }
    commit('appendContact', revealedContact);
    dispatch('progress/setProgress', null, { root: true });
    return createDirectoryPerson;
  },
  async deleteDirectoryPerson({ commit, dispatch }, id) {
    const {
      data: { deleteDirectoryPerson },
    } = await this.app.apolloProvider.defaultClient.mutate({
      mutation: DELETE_DIRECTORY_PERSON,
      variables: {
        id,
      },
    });
    if (deleteDirectoryPerson) {
      commit('removeContact', id);
      dispatch('progress/setProgress', null, { root: true });
    }
    return deleteDirectoryPerson;
  },
  async validateDeleteDirectoryPerson({ _commit }, id) {
    const {
      data: { validateDeleteDirectoryPerson },
    } = await this.app.apolloProvider.defaultClient.query({
      fetchPolicy: 'no-cache',
      query: VALIDATE_DELETE_DIRECTORY_PERSON,
      variables: {
        id,
      },
    });
    return validateDeleteDirectoryPerson;
  },
  async onDirectoryPersonCountryUpdate({ dispatch, state }, country) {
    if (state.user.residentialAddress?.country !== country) {
      await dispatch('charity-causes/getCauses', null, { root: true });
      await dispatch('product/getProducts', null, { root: true });
    }
  },
};
