import { updateField } from 'vuex-map-fields';
import { isSameRelationship } from '@/utilities';

export default {
  updateField,
  /**
   * Sets the user details in the state.
   * @param {Object} state - The Vuex state object.
   * @param {Object} data - The enriched user data.
   */
  setUserDetails(state, data) {
    state.user = data;
  },
  /**
   * Sets the contacts in the state.
   *
   * @param {Object} state - The Vuex state object.
   * @param {Array} data - An array of enriched user objects.
   */
  setContacts(state, data) {
    state.contacts = data
      .map((contact) => {
        const relationshipWithUser = state.user.relationships?.find(
          (relationship) => relationship.toPersonId === contact.id
        );

        return {
          ...contact,
          relationshipWithUser: relationshipWithUser?.type,
        };
      })
      .sort((a, b) => (a.createdAt < b.createdAt ? -1 : 1));
  },
  /**
   * Appends a contact to the state.contacts array and sorts the array based on createdAt property.
   * @param {Object} state - The Vuex state object.
   * @param {Object} data - The enriched user object to be appended to the contacts array.
   */
  appendContact(state, data) {
    data.relationshipWithUser = state.user.relationships?.find(
      (relationship) => relationship.toPersonId === data.id
    )?.type;
    state.contacts = [...state.contacts, data].sort((a, b) =>
      a.createdAt < b.createdAt ? -1 : 1
    );
  },
  removeContact(state, id) {
    state.contacts = state.contacts.filter((contact) => contact.id !== id);
  },
  upSearchUserRelationships(state, data) {
    state.user.relationships = [
      data,
      ...state.user.relationships.filter(
        (relationship) => !isSameRelationship(relationship, data)
      ),
    ];
  },
};
