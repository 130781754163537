import CREATE_DIRECTORY_PERSON_MUTATION from '@/graphql/mutations/CreateDirectoryPerson';
import UPDATE_DIRECTORY_PERSON_MUTATION from '@/graphql/mutations/UpdateDirectoryPerson';
import { SensitiveModel } from '@/plugins/sensitive/SensitiveModel';

const directoryPersonDefaults = {
  resourceCollection: 'DIRECTORY_PERSON',
  privacyTargetCollection: 'DIRECTORY_PERSON',
  creationResolver: (data) => Object.values(data)[0].directoryPerson,
  createMutation: CREATE_DIRECTORY_PERSON_MUTATION,
  updateMutation: UPDATE_DIRECTORY_PERSON_MUTATION,
};

export const DirectoryPerson = new SensitiveModel({
  ...directoryPersonDefaults,
  jsonMapping: {
    firstName: 'first_name',
    middleName: 'middle_name',
    lastName: 'last_name',
    altFirstName: 'alt_first_name',
    altMiddleName: 'alt_middle_name',
    altLastName: 'alt_last_name',
    countryOfBirth: 'country_of_birth',
    religion: 'religion',
    sex: 'sex',
  },
});
